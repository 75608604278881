import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { getTransactionListAction } from '../Redux/Action/HomeAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TransactionModal = ({ showTransaction, handleCloseTransaction }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { transList } = useSelector((state) => state?.home);

    useEffect(() => {
        if (showTransaction) {
            dispatch(getTransactionListAction());
        }
    }, [showTransaction]);

    console.log("transList", transList)

    return (
        <>
            <Modal
                className="cmn-modal"
                show={showTransaction}
                onHide={handleCloseTransaction}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4 px-4">{t("profile.trans")}</h2>
                    <div className="transactions-main">
                        {
                            Array.isArray(transList?.data) && transList?.data?.length > 0 ?
                                transList?.data?.map((res) => {
                                    return <div className="transaction-inner mb-3" key={res?._id}>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <h3>{t("profile.order_ID")} {res?.order_no || 'N/A'} <span>{res?.payment_refund === 2 && "(Refund)"}</span></h3>
                                                <p>{res?.createdAt ? moment(res?.createdAt).format('DD-MMM-YYYY') : 'N/A'} {res?.payment_at ? `at ${moment(Number(res?.payment_at)).format('hh:mm A')}` : ""}</p>
                                            </div>
                                        </div>
                                        <h4 style={{ color: `${res?.payment_refund === 2 ? `#198754` : `#a72a2f`} ` }}>{res?.payment_refund === 2 ? `+` : `-`}{res?.to_pay ? `$${res?.to_pay}` : '$0'}</h4>
                                    </div>
                                }) :
                                <p className='data-not-available'>
                                    {t("common-text.no-data-found")}
                                </p>
                        }

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TransactionModal