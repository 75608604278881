import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Rating } from 'react-simple-star-rating';
import { deliveryRatingAction, getListOrderAction, orderDetailsAction } from '../Redux/Action/OrderAction';
import moment from 'moment';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from "yup";

const FeedbackModal = ({ show, setShow, setShowFeedback, id, deliveryPartnerId, pagination, limit }) => {
    const dispatch = useDispatch();
    const { orderDetails } = useSelector((state) => state?.order);
    const [isLoader, setIsLoader] = useState(false);
    const [rateState, setRateState] = useState(0);

    useEffect(() => {
        if (id && setShow) {
            dispatch(orderDetailsAction({ id }));
        }
    }, [id, dispatch, setShow]);

    const orderModalOpen = () => {
        formik.resetForm();
        setShow(false)
        setShowFeedback(true)
    }

    const validationSchema = Yup.object({
        description: Yup.string().required("Description is required"),
    });

    const formik = useFormik({
        initialValues: {
            description: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            console.log("values", values);
            if (rateState === 0) {
                return toast.warn("Please rate the star");
            }
            let payload = {
                rating: rateState,
                description: values?.description,
                order_id: id,
                delivery_partner_id: deliveryPartnerId
            };
            setIsLoader(true);
            await dispatch(deliveryRatingAction(payload)).then((res) => {
                if (res?.payload?.status === 200) {
                    dispatch(getListOrderAction({ status: `2`, pagination, limit }));
                    toast.success(res?.payload?.message);
                    setShow(false)
                    setShowFeedback(true)
                } else {
                    toast.error(res?.payload?.message);
                    setShow(false)
                }
                setIsLoader(false);
                formik.resetForm();
            });
        },
    });

    console.log("orderDetails", orderDetails)
    return (
        <>
            <Modal
                className="cmn-modal"
                show={show}
                onHide={() => {
                    setShow(false)
                    formik.resetForm();
                }}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit} >
                        <h2 className="heading-25 mb-4">Feedback</h2>
                        <div className="feedback-main">
                            <div className="orders-card">
                                <div className="orderscard-top">
                                    <Row>
                                        <Col lg={3}>
                                            <img src={require("../Assets/images/orders.svg").default} />
                                        </Col>
                                        <Col lg={9}>
                                            <h3>Order Id {orderDetails?.order_no || 'N/A'}</h3>
                                            <p>Placed on {moment(orderDetails?.createdAt).format('DD-MMM-YYYY')}</p>
                                            <div className="d-flex">
                                                <h4>Item: {orderDetails?.items_quantity || '0'} </h4>
                                                <h4>Price: ${orderDetails?.items_total_price || '0'}</h4>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <h4>
                                How would you rate the delivery <br />
                                experience?
                            </h4>
                            <div className="d-flex justify-content-center mt-4 mb-3">
                                <Rating onClick={(e) => setRateState(e)} />
                            </div>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    name='description'
                                    as="textarea"
                                    rows={5}
                                    placeholder="Write description here..."
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Form.Group>
                            {formik.touched.description && formik.errors.description ? (
                                <div className="text-danger">
                                    {formik.errors.description}
                                </div>
                            ) : null}
                        </div>
                        <div className="feedback-btns">
                            <button type="submit" className="cmn-red-btn mb-0 w-100">
                                {isLoader &&
                                    <>
                                        <div class="spinner-border spinner-border-sm" role="status" >
                                        </div>{" "}
                                    </>
                                }
                                Submit Feedback
                            </button>
                            <button type="button" className="skip w-100" onClick={orderModalOpen}>
                                Skip
                            </button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FeedbackModal