import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userEditProfile } from "../Redux/Action/AuthAction";
import { useQuery } from "../utilis/commonFunction";

export default function CreateProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");
  const [isLoader, setIsLoader] = useState(false);

  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      dateOfBirth: Yup.date()
        .max(new Date(new Date().setDate(new Date().getDate() - 1)), "Date cannot be today or in the future")
        .required("Date of Birth is required"),
    }),
    onSubmit: async (values) => {
      console.log(values, "values");
      if (!file) {
        return toast.warn("Please upload an Image");
      }
      let formData = new FormData();
      formData.append("profile_pic", file);
      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("dob", values.dateOfBirth);
      formData.append("is_profile_completed", 1);
      setIsLoader(true);
      await dispatch(userEditProfile({ token, formData })).then((res) => {
        console.log("useEdit", res);
        if (res?.payload?.status === 200) {
          // toast.success("Profile register successfully");
          navigate(`/add-address?token=${token}`, { replace: true })
          localStorage.setItem("isProfile", 1);
        } else {
          toast.error(res?.payload?.message);
        }
        setIsLoader(false);
        // formik.resetForm()
      })
    },
  });

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    const isProfile = localStorage.getItem('isProfile');
    if (isProfile) {
      navigate(`/add-address?token=${token}`, { replace: true })
    }
  }, [navigate]);



  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col
              lg={6}
              className="px-0 d-flex align-items-center justify-content-center"
            >
              <div className="cmn-form">
                <h2 className="heading-30">Tell Us Your Name!</h2>
                <p className="sub-16 text-center">
                  Please fill your profile details
                </p>

                <Form onSubmit={formik.handleSubmit}>
                  <div className="cmn-form-fields">
                    <div className="image-upload-input">
                      <div className="image-upload-view">
                        {file && (
                          <img
                            className="view-img"
                            src={URL.createObjectURL(file)}
                            alt="Profile Preview"
                          />
                        )}
                        <img
                          src={require("../Assets/images/userico.svg").default}
                          alt="User Icon"
                        />
                      </div>
                      <div className="image-upload-camera">
                        <input type="file" onChange={handleChange} />
                        <img
                          src={require("../Assets/images/camera.svg").default}
                          alt="Camera Icon"
                        />
                      </div>
                    </div>

                    <Form.Group className="mb-3 form-inner">
                      <img
                        src={require("../Assets/images/user.svg").default}
                        alt="User Icon"
                      />
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <div className="text-danger">
                          {formik.errors.firstName}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3 form-inner">
                      <img
                        src={require("../Assets/images/user.svg").default}
                        alt="User Icon"
                      />
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />
                      {formik.touched.lastName && formik.errors.lastName && (
                        <div className="text-danger">
                          {formik.errors.lastName}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3 form-inner">
                      <img
                        src={require("../Assets/images/date.svg").default}
                        alt="Date Icon"
                      />
                      <Form.Control
                        type="date"
                        placeholder="Date of Birth"
                        name="dateOfBirth"
                        max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.dateOfBirth}
                      />
                      {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                        <div className="text-danger">{formik.errors.dateOfBirth}</div>
                      )}
                    </Form.Group>

                  </div>
                  <button type="submit" className="cmn-red-btn mb-0" disabled={isLoader ? true : false}>
                    {isLoader &&
                      <>
                        <div class="spinner-border spinner-border-sm" role="status" >
                        </div>{" "}
                      </>
                    }
                    Next
                  </button>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="px-0">
              <div className="left-img">
                <img
                  src={require("../Assets/images/leftimg.jpeg")}
                  alt="Left Side"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
