import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { Link, useNavigate, useParams } from "react-router-dom";
import AsNavFor from "../Components/SingleDishSlider";
import SingleItemsSlider from "../Components/SingleItemsSlider";
import LoginNowModal from "../Modals/loginNowModal";
import CommentsModal from "../Modals/commentsModal";
import { useDispatch, useSelector } from "react-redux";
import { productDetailsAction, productLikeAction, similarProductListAction } from "../Redux/Action/HomeAction";
import CustomStepper from "../Components/CustomStepper";

export default function SingleDish() {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const productDetails = useSelector((state) => state?.home?.productDetails?.data)
  const { similarProductList } = useSelector((state) => state?.home)
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [like, setLike] = useState(false)
  const handleShow = () => setShow(true);
  const handleShowTwo = () => setShowTwo(true);

  useEffect(() => {
    dispatch(productDetailsAction({ id })).then((res) => {
      setLike(res?.payload?.data?.is_product_liked)
      if (res?.payload?.data?.category_id?._id) {
        similarProduct(res?.payload?.data?.category_id?._id, res?.payload?.data?._id)
      }
    })
  }, [])

  const routeBack = () => {
    naviagte(-1)
  }

  const productLikeApi = async (productId) => {
    let payload = {
      product_id: productId,
    };
    await dispatch(productLikeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(productDetailsAction({ id }));
        setLike(res?.payload?.data?.is_product_liked)
      }
    });
  };

  const similarProduct = async (id, product_id) => {
    await dispatch(similarProductListAction({ id, product_id }))
  }

  return (
    <Layout>
      <Container>
        <Link onClick={routeBack} className="mt-4 d-block">
          <img src={require("../Assets/images/backimg.svg").default} />
        </Link>
        <div className="single-dish-main">
          <Row>
            <Col lg={6}>
              <div className="single-product-detail">
                <AsNavFor data={productDetails} />
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-dish-description">
                <div className="d-flex justify-content-between align-items-center">
                  <h2>{productDetails?.name || 'N/A'}</h2>
                  <button className={like ? "active-heart like-btn" : "like-btn"} onClick={() => { productLikeApi(productDetails?._id) }}>
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                        stroke="#A72A2F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="d-flex align-items-center mt-2 mb-2">
                  {" "}
                  <Rating
                    disableFillHover={true}
                    readonly={true}
                    initialValue={productDetails?.avg_ratings ?? 0} />
                  <h3
                    className="ms-4 mb-0"
                    onClick={handleShow}
                    style={{ cursor: "pointer" }}
                  >
                    {productDetails?.total_reviews_count ?? 0} Comments
                  </h3>
                </div>
                <h4>${productDetails?.price || '0'}</h4>

                <CustomStepper productDetails={productDetails} handleShowTwo={handleShowTwo} productId={id} />

              </div>
            </Col>
            <Col lg={12}>
              {" "}
              <div className="deals-sec mt-4">
                <div className="deal-cmn-heading mb-4">
                  <h2 className="heading-25">Similar Products</h2>
                  <span></span>
                </div>
                <SingleItemsSlider data={similarProductList?.data ?? []} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* Comment Modal */}
      <CommentsModal show={show} setShow={setShow} id={id} />

      {/* Login Modal */}
      <LoginNowModal showTwo={showTwo} setShowTwo={setShowTwo} />
    </Layout>
  );
}
