import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import Slider from "react-slick";
import { ImagePath } from "../utilis/ImageUrl";
import { productLikeAction, productListAction } from "../Redux/Action/HomeAction";
import { useDispatch } from "react-redux";
import ReactReadMoreReadLess from "react-read-more-read-less";

var settings = {
  infinite: false,
  nav: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};


export default function SingleItemsSlider({ data }) {
  const dispatch = useDispatch();
  const [likes, setLikes] = useState({});

  const SingleProduct = (id) => {
    window.location.href = `/single-dish/${id}`
  };

  const productLikeApi = async (productId) => {
    let payload = {
      product_id: productId,
    };
    await dispatch(productLikeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(productListAction());
        setLikes((prevLikes) => ({
          ...prevLikes,
          [productId]: res?.payload?.data?.is_product_liked,
        }));
      }
    });
  };

  const slider = React.useRef(null);
  return (
    <>
      <Slider {...settings} ref={slider} className="slick-slide-wrapper" >
        {
          Array.isArray(data) && data?.length > 0
          &&
          data?.map((res) => {
            return <>

              <div className="single-products-main pe-2">
                <button
                  className={likes[res?._id] ? "active-heart like-btn" : "like-btn"} onClick={() => { productLikeApi(res?._id) }}>
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                      stroke="#A72A2F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <img
                  onClick={() => SingleProduct(res?._id)}
                  className="main-image single"
                  src={res?.images?.length > 0 ? ImagePath(res?.images[0]) : require("../Assets/images/chesecale.png")}
                />
                <div className="d-flex justify-content-between">
                  <Rating initialValue={res?.avg_ratings ?? 0} disableFillHover={true} readonly={true} />
                  <img src={require("../Assets/images/arrow-right.svg").default} />
                </div>
                <h3>{res?.name || 'N/A'}</h3>

                <div className="similar-products-card">
                  <ReactReadMoreReadLess
                    charLimit={70}
                    readMoreText={"Read more "}
                    readLessText={"Read less "}
                    readMoreClassName="read-more-less--more"
                    readLessClassName="read-more-less--less"
                  >
                    {res?.description || 'N/A'}
                  </ReactReadMoreReadLess >
                </div>
                <div className="product-bottom">
                  <h4>${res?.price || '0'}</h4>
                </div>
              </div>

            </>
          })
        }

      </Slider >

      {
        data?.length === 0 &&
        <p className="data-not-available">No Data Found</p>
      }

      <div className="slick-buttons">
        <button onClick={() => slider?.current?.slickPrev()}>
          <img src={require("../Assets/images/arrowleft.svg").default} />
        </button>
        <button onClick={() => slider?.current?.slickNext()}>
          <img src={require("../Assets/images/arrowright.svg").default} />{" "}
        </button>
      </div>
    </>
  );
}
