import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ProductDetailsDesCard from "../CommonComponent/ProductDetailsDesCard";
import ProductDetailsSizeCard from "../CommonComponent/ProductDetailsSizeCard";
import ProductDetailsFillingCard from "../CommonComponent/ProductDetailsFillingCard";
import ProductDetailsToppingsCard from "../CommonComponent/ProductDetailsToppingsCard";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import useAuthToken from "../Hook/useAuthToken";
import {
  addCartAction,
  editCartAction,
  listCartAction,
  municipalityAction,
  outOfStockAction,
} from "../Redux/Action/CartAction";
import OutOfStockModal from "../Modals/outOfStockModal";
import MuncipalityModal from "../Modals/muncipalityModal";

const steps = ["Step 1", "Step 2", "Step 3"];

const validationSchema = Yup.object({
  size_id: Yup.string().required("Size is required"),
  filling_id: Yup.string().required("Filling is required"),
  topping_id: Yup.string().required("Topping is required"),
});

function CustomStepper({ productDetails, handleShowTwo, productId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const tokenData = useAuthToken();
  const [showTwo, setShowTwo] = useState(false);
  const [formDataState, setFormDataState] = useState({});
  const [showModalOpen, setShowModalOpen] = useState(false);
  const { cartList } = useSelector((state) => state.cart);
  const matchProductId = cartList?.data?.find((res) => {
    return res?.product_id === productId && res?.quantity;
  });

  const validateStep = (step, values) => {
    switch (step) {
      case 1:
        return !values.size_id;
      case 2:
        return !values.filling_id;
      case 3:
        return !values.topping_id;
      default:
        return false;
    }
  };
  const formik = useFormik({
    initialValues: {
      size_id: "",
      filling_id: "",
      topping_id: "",
      request: "",
      quantity: 1,
    },
    // validationSchema,
    onSubmit: async (values) => {

      if (validateStep(activeStep, values) && !shouldSkipStep(activeStep)) {
        const fieldName =
          activeStep === 1 ? "Size" : activeStep === 2 ? "Filling" : "Topping";
        return toast.warn(`Please select ${fieldName}`);
      }
      setFormDataState({ ...formDataState, ...values });
      if (!tokenData) {
        handleShowTwo();
      } else {
        await dispatch(outOfStockAction({ id: productId })).then((res) => {
          if (res?.payload?.status === 200) {
            if (res?.payload?.data?.is_out_of_stock == true) {
              setShowTwo(true);
            } else {
              dispatch(municipalityAction({ id: productId })).then((res) => {
                if (res?.payload?.data?.outside_municipality == true) {
                  setShowModalOpen(true);
                  setShowTwo(false);
                } else {
                  addToCartApi(values);
                }
              });
            }
          }
        });
      }
    },
  });

  useEffect(() => {
    if (matchProductId) {
      formik.setFieldValue("quantity", matchProductId?.quantity);
    }
  }, [matchProductId]);

  const addToCartApi = async (values) => {
    let payload = {
      product_id: productId,
      quantity: values?.quantity,
      size_id: values?.size_id,
      filling_id: values?.filling_id,
      topping_id: values?.topping_id,
      request: values?.request,
    };
    console.log("payload", payload);
    await dispatch(addCartAction(payload)).then((res) => {
      console.log("cart res", res);
      if (res?.payload?.status === 200) {
        toast.success("Added to Cart Successfully");
        navigate(`/cart`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  // const handleNext = () => {
  //   if (activeStep === 1) {
  //     if (!formik.values.size_id) {
  //       return toast.warn("Please select Size")
  //     } else {
  //       setActiveStep((prevStep) => prevStep + 1);
  //     }
  //   } else if (activeStep === 2) {
  //     if (!formik.values.filling_id) {
  //       return toast.warn("Please choose Filling")
  //     } else {
  //       setActiveStep((prevStep) => prevStep + 1);
  //     }
  //   } else if (activeStep < steps.length) {
  //     setActiveStep((prevStep) => prevStep + 1);
  //   }
  // };

  const handleNext = () => {
    if (allStepsSkipped) {
      toast.info("All steps skipped.");
      setActiveStep(steps.length);
      return;
    }

    if (
      activeStep === 1 &&
      !formik.values.size_id &&
      !shouldSkipStep(activeStep)
    ) {
      return toast.warn("Please select Size");
    } else if (
      activeStep === 2 &&
      !formik.values.filling_id &&
      !shouldSkipStep(activeStep)
    ) {
      return toast.warn("Please choose Filling");
    }

    let nextStep = activeStep + 1;
    while (nextStep < steps.length && shouldSkipStep(nextStep)) {
      nextStep++;
    }

    if (nextStep < steps.length) {
      setActiveStep(nextStep);
    } else {
      setActiveStep(steps.length);
    }
  };

  const handleToppingStep = () => {
    if (!formik.values.topping_id) {
      return toast.warn("Please Choose Topping");
    }
  };

  const handleBack = () => {
    let prevStep = activeStep - 1;
    while (prevStep >= 0 && shouldSkipStep(prevStep)) {
      prevStep--;
    }

    if (prevStep >= 0) {
      setActiveStep(prevStep);
    }
  };

  const shouldSkipStep = (step) => {
    if (!productDetails) {
      return true;
    }

    switch (step) {
      case 1:
        const skipSizeStep =
          !productDetails.product_sizes?.some((size) => size.price !== null) ||
          productDetails.product_sizes?.length === 0;
        return skipSizeStep;
      case 2:
        const skipFillingStep =
          !productDetails.product_fillings?.some(
            (filling) => filling.price !== null
          ) || productDetails.product_fillings?.length === 0;
        return skipFillingStep;
      case 3:
        const skipToppingStep =
          !productDetails.product_toppings?.some(
            (topping) => topping.price !== null
          ) || productDetails.product_toppings?.length === 0;
        return skipToppingStep;
      //   return false;
    }
  };

  const allStepsSkipped = [1, 2, 3].every((step) => {
    const skip = shouldSkipStep(step);
    return skip;
  });

  const renderStepContent = (step) => {
    if (shouldSkipStep(step)) {
      return <div />;
    }

    switch (step) {
      case 0:
        return <ProductDetailsDesCard data={productDetails} />;
      case 1:
        return <ProductDetailsSizeCard data={productDetails} formik={formik} />;
      case 2:
        return (
          <ProductDetailsFillingCard data={productDetails} formik={formik} />
        );
      case 3:
        return (
          <ProductDetailsToppingsCard data={productDetails} formik={formik} />
        );
      default:
        return <div />;
    }
  };

  const hasFurtherStep = (currentStep) => {
    for (let step = currentStep + 1; step <= 3; step++) {
      if (!shouldSkipStep(step)) {
        return true;
      }
    }
    return false;
  };

  const handleQuantity = async () => {
    if (matchProductId && formik.values.quantity !== matchProductId?.quantity) {
      let formdata = {
        quantity: formik.values.quantity,
      };
      const response = await dispatch(
        editCartAction({ id: matchProductId?._id, formdata })
      );
      if (response?.payload?.status === 200) {
        dispatch(listCartAction());
        toast.success(response?.payload?.message);
      } else {
        toast.error(response?.payload?.message);
      }
    }
  };

  const routeBack = () => {
    navigate(-1);
  };

  const isLastStep = activeStep === steps.length;
  const hasFurtherSteps = hasFurtherStep(activeStep);

  console.log(
    isLastStep,
    allStepsSkipped,
    "fdsfdsdfsdfsdfsdfsdf",
    hasFurtherSteps
  );

  const shouldShowFinalButtons = () => {
    return (
      activeStep >= steps.length - 2 || steps.slice(activeStep + 1).every(shouldSkipStep)
    );
  };


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="step-content">{renderStepContent(activeStep)}</div>
          <div className="quantity-main">
            <h3>Quantity</h3>
            <div>
              <button
                type="button"
                onClick={() =>
                  formik.setFieldValue("quantity", formik.values.quantity - 1)
                }
                disabled={formik.values.quantity <= 1}
              >
                <img
                  src={require("../Assets/images/minus.svg").default}
                  alt="minus"
                />
              </button>
              <span>{formik.values.quantity}</span>
              <button
                type="button"
                onClick={() =>
                  formik.setFieldValue("quantity", formik.values.quantity + 1)
                }
              >
                <img
                  src={require("../Assets/images/add.svg").default}
                  alt="add"
                />
              </button>
            </div>
            {formik.touched.quantity && formik.errors.quantity ? (
              <div>{formik.errors.quantity}</div>
            ) : null}
          </div>

          {
            <div className="buttons">
              {(  allStepsSkipped || isLastStep) ? (
                <div className="subsc-btn-set">
                  <div className="d-flex  align-items-baseline">
                    {activeStep > 0 && (
                      <button
                        type="button"
                        className="bg-none-btn"
                        onClick={handleBack}
                      >
                        <svg
                          width="25"
                          height="18"
                          viewBox="0 0 25 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 9L1.91379 10.0345L0.928571 9L1.91379 7.96552L3 9ZM23 7.5C23.8284 7.5 24.5 8.17157 24.5 9C24.5 9.82843 23.8284 10.5 23 10.5V7.5ZM8.58046 17.0345L1.91379 10.0345L4.08621 7.96552L10.7529 14.9655L8.58046 17.0345ZM1.91379 7.96552L8.58046 0.965517L10.7529 3.03448L4.08621 10.0345L1.91379 7.96552ZM3 7.5L23 7.5V10.5L3 10.5L3 7.5Z"
                            fill="#40413A"
                          />
                        </svg>
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => navigate("/subscribed-products")}
                      className="subscribe-btn ms-2 d-block w-100"
                    >
                      Subscribe
                    </button>

                    <button
                      type="submit"
                      className="cmn-red-btn ms-3 mt-0 mb-0"
                      // onClick={handleToppingStep}
                    >
                      Add to Cart $
                      {formik?.values?.quantity * productDetails?.price}
                    </button>
                  </div>
                  <small
                    id="emailHelp"
                    class="form-text ms-4"
                    onClick={routeBack}
                  >
                    Continue Shopping
                  </small>
                </div>
              ) : (
                <div className="d-flex align-items-center gap-3">
                  {(activeStep !== 0 ) && (
                    <button
                      type="button"
                      className="bg-none-btn"
                      onClick={handleBack}
                    >
                      <svg
                        width="25"
                        height="18"
                        viewBox="0 0 25 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 9L1.91379 10.0345L0.928571 9L1.91379 7.96552L3 9ZM23 7.5C23.8284 7.5 24.5 8.17157 24.5 9C24.5 9.82843 23.8284 10.5 23 10.5V7.5ZM8.58046 17.0345L1.91379 10.0345L4.08621 7.96552L10.7529 14.9655L8.58046 17.0345ZM1.91379 7.96552L8.58046 0.965517L10.7529 3.03448L4.08621 10.0345L1.91379 7.96552ZM3 7.5L23 7.5V10.5L3 10.5L3 7.5Z"
                          fill="#40413A"
                        />
                      </svg>
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={handleNext}
                    className="cmn-red-btn"
                    disabled={activeStep === steps.length}
                  >
                    Proceed
                  </button>
                </div>
              )}
            </div>
          }
        </div>
      </form>
      <OutOfStockModal
        showTwo={showTwo}
        setShowTwo={setShowTwo}
        setShowModalOpen={setShowModalOpen}
        formDataState={formDataState}
        productId={productId}
      />

      <MuncipalityModal
        showModalOpen={showModalOpen}
        setShowModalOpen={setShowModalOpen}
        formDataState={formDataState}
        productId={productId}
      />
    </>
  );
}

export default CustomStepper;
