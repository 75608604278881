import React from 'react'
import { Modal } from 'react-bootstrap'

const CheckoutAlerts = ({ alertShow, setAlertShow }) => {
    const formattedMessage = alertShow?.message?.replace(/\n/g, "<br />");

    return (
        <Modal
            className="cmn-modal"
            show={alertShow.modal}
            onHide={() => setAlertShow({ ...alertShow, modal: false })}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h2 className="heading-25 mb-4 px-4">
                    {
                        alertShow?.type === 2 ? `Instant delivery` : `Delivery Alert`
                    }
                </h2>
                <div className="promocodes-wrap">
                    <div
                        dangerouslySetInnerHTML={{ __html: formattedMessage }}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CheckoutAlerts
